/* header section */

.navbar-logo-title {
	font-size: 1.5rem;
	text-transform: uppercase;
	color: #172e4a;
	padding: 0px;
}

a.navbar-logo-title.nav-link img {
	display: block;
	max-width: 225px;
	padding: 0px;
}

.navbar-logo-title:hover {
	color: black;
}

.navbar-logo-title:focus {
	color: black;
}

.strong {
	color: #c1995f;
}

.strong:hover {
	color: #c1995f;
}

.strong:focus {
	color: #c1995f;
}

.btn {
	border: 0;
	text-shadow: none;
	min-width: 130px;
	height: 38px;
	font-size: 1.125rem;
	line-height: 38px;
	border-radius: 3px;
	padding: 0 15px;
	font-weight: 600;
	-webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-ms-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	transition: all 400ms ease-in-out;
	color: #fff;
}

.primary-grad,
.btn.btn-primary-grad,
.btn.btn-primary-grad:hover,
.btn.btn-primary-grad:focus {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ae6b2f+0,c1995f+100 */
	background: #ae6b2f;
	/* Old browsers */
	background: -moz-linear-gradient(left, #ae6b2f 0%, #c1995f 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(left, #ae6b2f 0%, #c1995f 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #ae6b2f 0%, #c1995f 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ae6b2f', endColorstr='#c1995f',GradientType=1 );
	/* IE6-9 */
}

.btn:focus {
	box-shadow: none !important;
}

.btn:hover {
	color: #fff;
}

.navbar-header-menu {
	font-size: 1rem;
	margin-right: 15px;
	color: #7a7a7a;
}

.navbar-header-menu:hover {
	text-decoration: none;
}

.navbar {
	margin-bottom: 0;
	padding: 23px 0px;
	border: 0;
	border-radius: 0;
	background: #ffffff;
	-webkit-transition: padding 400ms ease-in-out;
	-moz-transition: padding 400ms ease-in-out;
	-ms-transition: padding 400ms ease-in-out;
	-o-transition: padding 400ms ease-in-out;
	transition: padding 400ms ease-in-out;
	-webkit-box-shadow: 0 0 13px 3px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0 13px 3px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 13px 3px rgba(0, 0, 0, 0.15);
}
.navbar.nav-up {
	padding: 15px 0px;
}
.navbar .navbar-header .navbar-brand {
	font-size: 1.5rem;
	text-transform: uppercase;
	color: #172e4a;
	padding: 0px;
}
.navbar .navbar-header .navbar-brand strong {
	color: #c1995f;
}
.navbar .navbar-header .navbar-brand:hover {
	color: initial;
}
.navbar .navbar-header .navbar-toggle {
	border: 0;
	margin-right: 0;
}
.navbar .navbar-header .navbar-toggle .icon-bar {
	background: #c1995f;
	width: 30px;
	height: 3px;
}
.navbar .navbar-collapse.collapse .navbar-right li.open a {
	background: transparent;
}
.navbar .navbar-collapse.collapse .navbar-right li.open a:focus,
.navbar .navbar-collapse.collapse .navbar-right li.open a:hover {
	background: transparent;
}
.navbar .navbar-collapse.collapse .navbar-right .dropdown-menu {
	padding: 0;
	border-radius: 0;
	-webkit-box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.175);
	-moz-box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.175);
	box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.175);
}
.navbar .navbar-collapse.collapse .navbar-right .dropdown-menu li a {
	padding: 10px 15px;
	text-transform: uppercase;
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	font-size: 12px;
}
.navbar .navbar-nav li a {
	padding: 14px 20px;
	font-size: 1.125rem;
	color: #7a7a7a;
}
.navbar .navbar-nav li a:hover,
.navbar .navbar-nav li a:focus {
	background: transparent;
	color: #000000;
}
.navbar .navbar-nav li a.btn-primary-grad {
	padding: 0;
	width: 131px;
	text-align: center;
	color: #ffffff;
	text-transform: uppercase;
	margin-top: 6px;
	line-height: 38px;
	font-weight: 700;
}
.navbar .navbar-nav li a.btn-primary-grad:hover,
.navbar .navbar-nav li a.btn-primary-grad:focus {
	color: #ffffff;
}
.navbar .navbar-nav li.user-dropdown > a {
	color: #c1995f;
	position: relative;
}
.navbar .navbar-nav li.user-dropdown > a:before {
	content: '';
	position: absolute;
	height: 70%;
	top: 15%;
	display: block;
	left: 0;
	border-left: 1px solid #dfdfdf;
}
.navbar .navbar-nav li.user-dropdown > a i {
	padding-left: 15px;
	font-weight: 700;
}
.navbar .navbar-nav.navbar-right {
	margin: 0;
}
.navbar-collpase-mob {
	padding-left: 0;
	padding-right: 0;
}
@media (max-width: 767px) {
	.navbar {
		padding: 10px 0;
	}
	.navbar .navbar-header .navbar-brand {
		font-size: 1.5rem;
		text-transform: uppercase;
		color: #172e4a;
	}
	.navbar .navbar-header .navbar-brand strong {
		color: #c1995f;
	}
	.navbar .navbar-header .navbar-brand:hover {
		color: initial;
	}
	.navbar .navbar-header .navbar-toggle {
		border: 0;
		margin-right: 0;
		padding: 9px 15px;
		margin-bottom: 0;
	}
	.navbar .navbar-nav li {
		text-align: center;
	}
	.navbar .navbar-nav li a {
		font-size: 14px;
	}
	.navbar .navbar-nav li a.btn-primary-grad {
		margin: 6px auto 0;
		display: block;
	}
	.navbar-collpase-mob {
		padding-left: 15px;
		padding-right: 15px;
	}

	a.navbar-logo-title.nav-link img {
		display: block;
		max-width: 150px;
		height: auto;
		padding: 0px;
	}
}

/* footer section */

footer .footer-bg {
	position: relative;
	background: url('../assets/images/footer-bg.jpg') no-repeat border-box center center;
	background-size: cover;
	width: 100%;
	text-align: center;
	padding-top: 80px;
	padding-bottom: 30px;
}
footer .footer-bg:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(15, 15, 15, 0.85);
}
footer .footer-bg h2 {
	font-size: 1.875rem;
	color: #ffffff;
	margin: 0 0 2.1875rem 0;
}
footer .footer-bg .btn {
	width: 212px;
	height: 51px;
	line-height: 46px;
}
footer .footer-bg .footer-logo {
	margin-top: 60px;
	margin-bottom: 35px;
	position: relative;
}
footer .footer-bg .footer-logo .logo-wrapper {
	width: 170px;
	display: block;
	margin: auto;
}
footer .footer-bg .footer-logo .logo-wrapper:before,
footer .footer-bg .footer-logo .logo-wrapper:after {
	content: '';
	position: absolute;
	border-bottom: 1px solid #333333;
	-moz-transform: translate(0, 50%);
	-o-transform: translate(0, 50%);
	-ms-transform: translate(0, 50%);
	-webkit-transform: translate(0, 50%);
	transform: translate(0, 50%);
	width: calc(50% - 85px);
	top: 50%;
}
footer .footer-bg .footer-logo .logo-wrapper:before {
	left: 0;
}
footer .footer-bg .footer-logo .logo-wrapper:after {
	right: 0;
}
footer .footer-bg .footer-logo .logo-wrapper img {
	display: block;
	margin: auto;
	max-width: 100px;
}
footer .footer-bg .footer-nav {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	justify-content: center;
	text-align: center;
}
footer .footer-bg .footer-nav li {
	padding: 0 15px;
}
footer .footer-bg .footer-nav li a {
	color: #7a7a7a;
}
footer .footer-bg .copyright {
	margin-top: 40px;
	font-size: 14px;
	font-weight: 300;
	color: #7a7a7a;
}
footer .footer-bg .container {
	position: relative;
	z-index: 2;
}
@media (min-width: 1200px) {
	footer .footer-bg .container {
		padding: 0 60px;
	}
}
@media (max-width: 767px) {
	footer .footer-bg {
		height: auto;
		padding-bottom: 25px;
	}
	footer .footer-bg h2 {
		line-height: 30px;
	}
	footer .footer-bg .btn {
		width: 170px;
		height: 45px;
		line-height: 40px;
	}
	footer .footer-bg .footer-nav li {
		padding: 0 15px;
		margin-bottom: 10px;
		display: block;
	}
	footer .footer-bg .footer-nav li:last-child {
		margin-bottom: 0;
	}
}
.btn.btn-premium {
	background: transparent;
	color: #c1995f;
	border: 2px solid #c1995f;
}

.nav-link-bg {
	background-color: transparent;
	border: none;
	margin-top: 40px;
	font-size: 15px;
	font-weight: 300;
	color: #7a7a7a;
	line-height: 30px;
}

.nav-link-bg:hover {
	background-color: transparent;
	border: none;
	margin-top: 40px;
	font-size: 15px;
	font-weight: 300;
	color: #7a7a7a;
	text-decoration: underline;
	line-height: 30px;
}

/* recent list section css */

/*common section styling*/
.recent-reviews {
	padding: 0 0 20px 0;
	position: relative;
}
.recent-reviews .see-more-wrapper button {
	width: 120px;
}
.recent-reviews .see-more-wrapper i {
	transition: margin 900ms ease-in-out;
}
.recent-reviews h2 {
	font-size: 2.25rem;
	font-family: 'Playfair Display', serif;
	text-transform: capitalize;
	margin: 0 0 3rem 0;
	color: #172e4a;
	text-align: center;
}
.recent-reviews a {
	text-decoration: none;
}
.recent-review-header {
	display: grid;
	grid-template-columns: 1fr auto;
	justify-content: center;
	align-items: center;
}
.recent-reviews .recent-review-header .total-rating.success {
	position: static;
	transform: none;
}
.total-rating.success .rating-value-wrapper {
	justify-content: flex-end;
}
@media (max-width: 767px) {
	.recent-reviews {
		padding: 40px 0;
	}
	.recent-reviews .reviews-card-wrapper .rc-body .rating-points {
		margin-right: 0px;
	}

	.recent-review-header {
		margin-bottom: 20px;
	}

	.recent-review-header h5 {
		margin-bottom: 0px !important;
	}

	.recent-reviews .reviews-card-wrapper .rc-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
	.reviews-card-wrapper .rc-head .total-rating {
		text-align: center;
	}
	.total-rating.success .rating-value-wrapper {
		justify-content: center;
	}
}

.error {
	color: #ff0000;
}

.success {
	color: #58cc19;
}

.warning {
	color: #f8a124;
}

.search-results-wrap {
	padding: 2.8125rem 0;
}
.search-results-wrap h2 {
	margin-bottom: 2.5rem;
	font-size: 1.875rem;
	font-weight: 300;
	font-family: 'Source Sans Pro', sans-serif;
}
.search-results-wrap .reviews-card-wrapper {
	margin: 0;
}

.no-deco:hover,
.reviews-card-wrapper li a:hover,
.no-deco:focus,
.reviews-card-wrapper li a:focus {
	text-decoration: none;
}

.paper,
.sign-in-wrapper,
.reviews-card-wrapper li,
.rate-judge-wrap .rating-overlay,
.judge-all-ratings .all-ratings > div ul li {
	background: #ffffff;
	-webkit-box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.15);
}

.sign-in-wrapper .logo-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 75px;
}

.sign-in-wrapper .logo-wrapper > img {
	max-width: 225px;
}

.recent-reviews h2 {
	margin-bottom: 2.5rem;
}

.reviews-card-wrapper {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
.reviews-card-wrapper li {
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
	border: 1px solid #dfdfdf;
}
.reviews-card-wrapper li:last-child {
	margin-bottom: 0;
}
.reviews-card-wrapper li:hover {
	border-color: #c1995f;
}
.reviews-card-wrapper li a {
	display: block;
}
.reviews-card-wrapper .rc-head {
	position: relative;
}
.reviews-card-wrapper .rc-head h5 {
	font-size: 1.5625rem;
	font-weight: 600;
	margin: 0 0 15px;
	color: #333333;
	text-decoration: underline;
	text-decoration-color: var(--primary);
}
.reviews-card-wrapper .rc-head h5:hover {
	text-decoration: none;
}
.reviews-card-wrapper .rc-head .total-rating {
	font-size: 3rem;
	font-weight: 700;
	margin: 0;
	text-align: right;
	margin-bottom: 5px;
	margin-top: 10px;
	line-height: 2.8125rem;
}
.reviews-card-wrapper .rc-body {
	padding-top: 18px;
	margin-top: 18px;
	border-top: 1px solid #edeff2;
}
.reviews-card-wrapper .rc-body .rating-points {
	margin-right: 2.5rem;
}
.reviews-card-wrapper .rc-body .rating-points:first-child {
	margin-left: 0;
}
.reviews-card-wrapper .rc-body .rating-points span {
	color: #172e4a;
	font-weight: 600;
	height: 45px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.reviews-card-wrapper .rc-body .rating-points h3 {
	font-size: 2.8125rem;
	font-weight: 200;
	margin: 0;
}

.judge-info > span {
	margin-left: 1.875rem;
}
.judge-info > span:first-child {
	margin-left: 0;
}
.judge-info span,
.judge-info i {
	color: #9ba9bb;
	margin-right: 5px;
}

.inline-middle,
footer .footer-bg .footer-nav li,
.search-form .search-ques span,
.search-form .search-ques a,
.states ul li,
.reviews-card-wrapper .rc-body .rating-points,
.judge-info span,
.judge-info i,
.rate-judge-wrap h2 a,
.rate-judge-wrap h2 .judge-number,
.rate-judge-wrap .judge-address i,
.rate-judge-wrap .judge-address span,
.rate-judge-wrap .judge-address a,
.main-banner .btn-secondary i {
	display: inline-block;
	vertical-align: middle;
}

/* Detail List css */

.judge-all-ratings .all-ratings ul li .rc-right .rc-ques-ans span,
.toggle-questions .toggle-group,
.toggle-questions p {
	display: inline-block;
	vertical-align: top;
}

.judge-all-ratings .all-ratings ul li:before,
.judge-all-ratings .all-ratings ul li .rc-left .rating-points:before,
.group:after,
.judge-all-ratings .all-ratings ul li:after,
.judge-all-ratings .all-ratings ul li .rc-left .rating-points:after {
	content: '';
	display: table;
}

.judge-all-ratings .all-ratings ul li,
.judge-all-ratings .all-ratings ul li .rc-left .rating-points {
	zoom: 1;
}

.judge-all-ratings .all-ratings ul li:after,
.judge-all-ratings .all-ratings ul li .rc-left .rating-points:after {
	clear: both;
}

.judge-all-ratings {
	padding: 80px 0;
	position: relative;
}

.judge-all-ratings h2 {
	font-size: 1.9rem;
	font-family: 'Playfair Display', serif;
	text-transform: capitalize;
	font-weight: normal;
	margin: 0 0 20px;
	color: #172e4a;
	text-align: left;
}

@media (max-width: 767px) {
	.judge-all-ratings {
		padding: 40px 0;
	}

	.sign-in-wrapper .logo-wrapper > img {
		max-width: 150px;
	}
}

.judge-all-ratings .all-ratings ul li {
	display: flex;
	background: #ffffff;
	-webkit-box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.15);
}

.judge-all-ratings {
	padding: 2.8125rem 0;
}

.judge-all-ratings .all-ratings ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.judge-all-ratings .all-ratings ul li {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-ms-box-shadow: none;
	box-shadow: none;
	border-radius: 10px;
	margin-bottom: 20px;
	border: 1px solid #dfdfdf;
}

.judge-all-ratings .all-ratings ul li .rc-internal-wrap {
	padding: 20px;
}
.rc-internal-wrap .rc-container {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}
.rc-internal-wrap .rc-container .time {
	font-size: 15px;
	font-weight: 600;
	text-align: right;
	white-space: nowrap;
}

.judge-all-ratings .all-ratings ul li .rc-left {
	flex: 0.3;
	border-right: 1px solid #dfdfdf;
	vertical-align: top;
}

.judge-all-ratings .all-ratings ul li .rc-left time {
	color: #9ba9bb;
}

.judge-all-ratings .all-ratings ul li .rc-left h3 {
	font-size: 2.8125rem;
	margin: 10px 0 20px;
	font-weight: normal;
	line-height: 34px;
}

.judge-all-ratings .all-ratings ul li .rc-left .rating-points {
	margin-bottom: 10px;
}

.judge-all-ratings .all-ratings ul li .rc-left .rating-points:last-child {
	margin-bottom: 0;
}

.judge-all-ratings .all-ratings ul li .rc-left .rating-points span {
	float: left;
	font-size: 12px;
	color: #67707b;
}

.judge-all-ratings .all-ratings ul li .rc-left .rating-points h6 {
	float: right;
	margin: 0;
	font-size: 15px;
	font-weight: 600;
}

.judge-all-ratings .all-ratings ul li .rc-right {
	flex: 0.7;
}

.judge-all-ratings .all-ratings ul li .rc-right .rc-ques-ans {
	margin-top: 10px;
	margin-bottom: 1.875rem;
}

.judge-all-ratings .all-ratings ul li .rc-right .rc-ques-ans span {
	color: #172e4a;
	font-size: 12px;
	background: #edeff2;
	padding: 5px 10px;
	margin-bottom: 10px;
}

.judge-all-ratings .all-ratings ul li .rc-right .rc-ques-ans span {
	margin-right: 10px;
}

.judge-all-ratings .all-ratings ul li .rc-right .rc-ques-ans span strong {
	font-weight: 600;
}

.judge-all-ratings .all-ratings ul li .rc-right p {
	color: #172e4a;
	font-weight: 300;
	line-height: 1.875rem;
	margin-bottom: 0;
}

.judge-all-ratings .all-ratings .slick-dots li {
	width: auto;
	height: auto;
	margin: 0;
	vertical-align: middle;
}

.judge-all-ratings .all-ratings .slick-dots li button {
	padding: 0;
	width: 15px;
	height: 20px;
}

.judge-all-ratings .all-ratings .slick-dots li button:before {
	font-size: 7px;
	width: auto;
	height: auto;
	color: #dfdfdf;
	opacity: 1;
	position: static;
}

.judge-all-ratings .all-ratings .slick-dots li.slick-active {
	top: -1px;
}

.judge-all-ratings .all-ratings .slick-dots li.slick-active button {
	padding: 0;
}

.judge-all-ratings .all-ratings .slick-dots li.slick-active button:before {
	font-size: 12px;
	color: #dfdfdf;
}

.notification-wrap {
	color: #fff;
	padding: 0 30px;
	text-align: center;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1030;
	height: 62px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ae6b2f+0,c1995f+100 */
	background: #ae6b2f;
	/* Old browsers */
	background: -moz-linear-gradient(left, #ae6b2f 0%, #c1995f 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(left, #ae6b2f 0%, #c1995f 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #ae6b2f 0%, #c1995f 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ae6b2f', endColorstr='#c1995f',GradientType=1 );
}

.header-top-close {
	background-color: transparent;
	border: none;
	color: #ffff;
	margin-left: 5px;
	line-height: 12px;
}
.header-top-close:hover {
	background: unset;
}

.header-top-close img {
	height: 12px;
}

@media (max-width: 992px) {
	.navbar-nav a,
	.navbar-header-menu {
		margin: 0px;
		text-align: center;
	}
	.navbar-nav .btn {
		height: auto;
		line-height: 35px;
		border-radius: 3px;
		padding: 0 40px;
		margin: 6px;
	}
	.notification-wrap {
		padding: 0px 20px;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg .navbar-collapse {
		justify-content: flex-end;
	}
	.navbar-collapse > div {
		align-items: baseline;
	}
}

@media (max-width: 475px) {
	.notification-wrap span {
		font-size: 14px;
	}
}

.navbar-light .navbar-toggler {
	padding: 0px;
	border: none;
}

.navbar-toggler-icon {
	height: 35px;
	width: 35px;
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: 0 0 0 0;
}

.rating-wrapper {
	position: relative;
	/* text-align: center; */
}

.stars-wrapper,
.meter-wrapper {
	display: flex;
	align-items: center;
	/* justify-content: center; */
	margin-top: 8px;
}

.meter-wrapper .meter-box {
	width: 35px;
	height: 25px;
	text-align: center;
	font-weight: 700;
	cursor: pointer;
	transition: all 300ms ease-in-out;
}

.meter-wrapper .meter-box.left-radius {
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.meter-wrapper .meter-box.right-radius {
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}

.meter-wrapper .meter-box.margin-left {
	margin-left: 3px;
}

.rating-value-wrapper {
	display: flex;
	flex-direction: row;
}

.rating-value-wrapper sup {
	font-size: 0.5em;
	letter-spacing: 6px;
}

.rating-value-wrapper ~ sub {
	font-size: 0.4em;
	color: black;
}
