:root {
	--primary: #c1995f;
	--secondary: #58cc19;
	--transparent: transparent;
	--error: #ff0000;
	--orange: #f8a124;
	--yellow: #ffff00;
	--lightGreen: #58cc19;
	--darkGreen: #006400;
	--white: #ffffff;
	--black: #000000;
	--gray: #9ba9bb;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

* {
	margin: 0;
}
*:focus {
	outline: 0;
}

html {
	height: 100%;
	font-size: 16px;
}
@media (max-width: 991px) {
	html {
		font-size: 14px;
	}
}
@media (max-width: 767px) {
	html {
		font-size: 12px;
	}
}

body {
	height: 100%;
	background: #f9f9f9;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 16px;
	-webkit-transition: padding-top 400ms ease-in-out;
	-moz-transition: padding-top 400ms ease-in-out;
	-ms-transition: padding-top 400ms ease-in-out;
	-o-transition: padding-top 400ms ease-in-out;
	transition: padding-top 400ms ease-in-out;
}

a {
	color: 'Playfair Display', serif;
}
a:hover,
a :focus {
	background: inherit;
	outline: 0;
	color: inherit;
}

label {
	font-weight: 500;
	margin: 0;
}

h1 {
	font-family: 'Source Sans Pro', sans-serif;
}

h2 {
	font-family: 'Source Sans Pro', sans-serif;
}

h3 {
	font-family: 'Source Sans Pro', sans-serif;
}

h4 {
	font-family: 'Source Sans Pro', sans-serif;
}

h5 {
	font-family: 'Source Sans Pro', sans-serif;
}

h6 {
	font-family: 'Source Sans Pro', sans-serif;
}

h1 {
	font-size: 2.8125rem;
}

@media (min-width: 1200px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1140px !important;
	}
}

.form-group {
	margin-bottom: 15px;
}

.sign-in-wrapper .sign-up-questions .drop-signup-wrap {
	margin: 1.5625rem 0 2.1875rem;
}

.main-banner .spinner,
.lets-start .spinner {
	display: block;
	text-align: center;
	margin-top: 75px;
}

.main-banner .dropdown-toggle.btn {
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
}

.fv-plugins-message-container {
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
}

.fv-help-block {
	color: #a94442;
	font-size: 85%;
}

.sign-up-wrapper .fv-plugins-message-container {
	display: block;
	margin-top: -05px;
	margin-bottom: 0px;
}

.sign-up-wrapper .custom-dropdown {
	background-color: #fff;
	color: black;
	width: 100%;
	text-align: left;
	border: 1px solid lightgray;
	font-size: 0.8rem;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 4px;
}
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 7px;
}

.initialLoaderScreen {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0px;
	padding: 0px;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	background-color: 'grey';
}

.initialLoaderScreen img {
	width: 100px;
}

/* Common styling */
.flex {
	display: flex;
}

.justify-between {
	justify-content: space-between;
}

.align-center {
	align-items: center;
}

.pointer:hover {
	cursor: pointer;
}

.inline {
	display: inline;
}

.block {
	display: block;
}

.error-text {
	color: var(--error);
}

.text-right {
	text-align: right;
}
